import Vue from "vue";
import App from "./App.vue";
import router from "./router";
Vue.prototype.$bus = new Vue({});
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

// import MockService from "@/core/mock/mock.service";
import Notifications from "vue-notification";

import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";

Vue.config.productionTip = false;
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;
Vue.use(Notifications);

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import moment from "moment";

// Check middleware phân quyền
 import permissionMiddleware from "@/core/mixins/permissionMiddleware"

Vue.filter("formatDate", function (value) {
  if (value) {
    return moment.utc(value).format("MM/DD/YY hh:mm:ss A");
  }
});

Vue.filter("formatDateTime", function (value) {
  let date = new Date(value);
  return date.toLocaleString();
});

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {

  // Check quyền
  if (!permissionMiddleware(to.name)){
    next({ name: '403' })
  }

  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

import InputTag from "vue-input-tag";
Vue.component("input-tag", InputTag);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");