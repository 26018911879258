import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import { SET_PERSONAL_INFO } from "./profile.module";

// action types
export const URL_LOGIN = "auth/login";
export const URL_LOGIN_GOOGLE = "auth/login-google";
export const URL_VERIFY = "auth/verify";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGIN_GOOGLE = "loginGoogle";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_PERMISSION = "permission";
export const SET_USERINFO = "userInfo";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post(URL_LOGIN, credentials)
        .then(({ data }) => {
          if (data.error_code !== 0) {
            context.commit(SET_ERROR, data.message);
            resolve(data);
          } else {
            localStorage.setItem("mytime", Date.now());
            context.commit(SET_AUTH, data.data.user);
            context.commit(SET_PERSONAL_INFO, data.data.user);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.message);
        });
    });
  },
  [LOGIN_GOOGLE](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post(URL_LOGIN_GOOGLE, credentials)
        .then(({ data }) => {
          if (data.error_code !== 0) {
            context.commit(SET_ERROR, data.message);
            resolve(data);
          } else {
            context.commit(SET_AUTH, data.data.user);
            resolve(data);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.message);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(URL_VERIFY)
        .then(({ data }) => {
          if (data.error_code !== 0) {
            context.commit(PURGE_AUTH);
          } else {
            context.commit(SET_AUTH, data.data.user);
            context.commit(SET_PERSONAL_INFO, data.data.user);
            window.localStorage.setItem(
              SET_PERMISSION,
              JSON.stringify(data.data.user.permission)
            );
            window.localStorage.setItem(
              SET_USERINFO,
              JSON.stringify(data.data.user)
            );
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(({ response }) => {
          context.commit(PURGE_AUTH);
          this.$router.push({ name: "login" });
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = "";
    JwtService.saveToken(state.user.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = "";
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
