import Vue from "vue";
import Notifications from "vue-notification";
Vue.use(Notifications);
export default function interceptors(error) {
  if (!error.response) {
    this.notifyAlert("warn", "Network/Server error");
    return;
  }

  // all the other error responses
  switch (error.response.status) {
    case 400:
      this.notifyAlert("warn", "Data Not Found");
      break;
    case 401: // authentication error, logout the user
      this.notifyAlert("warn", " authentication error, logout the user");
      break;
    case 403: // authentication error, logout the user
      notifyAlert("warn", "Bạn không có quyền truy cập");
      break;
    default:
      notifyAlert("warn", "Chưa cấu hình mã lỗi");
  }
  return;
}
function notifyAlert(type = "warn", message = "Có lỗi xẩy ra") {
  Vue.notify({
    group: "foo",
    type: type,
    title: message,
  });
}
